import { AttributeName } from '@wh/common/chapter/types/AttributeName'
import { formatNumber, formatPrice } from '@wh/common/chapter/lib/formatter'
import { calculatePsValue } from '@bbx/common/lib/CalculatePsValue'
import { RealEstatePropertyTypeFormatter } from '@bbx/common/lib/realEstatePropertyTypeFormatter'
import { AttributeValueFormatter } from '@bbx/common/lib/AttributeValueFormatter'

type Prefix = '€' | 'ab'
type Postfix = 'kg' | 'g/km' | 'ccm' | 'cm' | 'km' | 'l/100km' | 'EZ' | 'm²' | 'kWh'

interface AttributeDefinition {
    description: string
    name: AttributeName
    prefix?: Prefix
    postfix?: Postfix
    formatter?: AttributeValueFormatter
}

const NumberFormatter: AttributeValueFormatter = (value: string) => {
    return formatNumber(value)
}

const PriceFormatter: AttributeValueFormatter = (value: string) => {
    return formatPrice(value)
}

const realEstateAttributeDefinitions_objectInformation: AttributeDefinition[] = [
    { description: 'Objekttyp', name: 'PROPERTY_TYPE', formatter: RealEstatePropertyTypeFormatter },
    { description: 'Besitzform', name: 'PLOT/OWNED' },
    { description: 'Besitzform', name: 'OWNAGETYPE' },
    { description: 'Lage', name: 'PLOT/CONDITION' },
    { description: 'Zimmer', name: 'NO_OF_ROOMS' },
    { description: 'Zimmer', name: 'NUMBER_OF_ROOMS' },
    { description: 'Schlafzimmer', name: 'NO_OF_BEDROOMS' },
    { description: 'Betten', name: 'NO_OF_BEDS' },
    { description: 'Wohnfläche', name: 'ESTATE_SIZE/LIVING_AREA', postfix: 'm²', formatter: NumberFormatter },
    { description: 'Freifläche', name: 'FREE_AREA/FREE_AREA_AREA', postfix: 'm²', formatter: NumberFormatter },
    { description: 'Freifläche', name: 'FREE_AREA/FREE_AREA_AREA_TOTAL', postfix: 'm²', formatter: NumberFormatter },
    { description: 'Etagenanzahl', name: 'NO_OF_FLOORS' },
    { description: 'Stockwerk(e)', name: 'FLOOR' },
    { description: 'Grundfläche', name: 'PLOT/AREA', postfix: 'm²', formatter: NumberFormatter },
    { description: 'Gesamtfläche', name: 'ESTATE_SIZE/GROSS_AREA', postfix: 'm²', formatter: NumberFormatter },
    { description: 'Nutzfläche', name: 'ESTATE_SIZE/USEABLE_AREA', postfix: 'm²', formatter: NumberFormatter },
    { description: 'Umgebung', name: 'LEISURE_SITUATION' },
    { description: 'Verfügbar', name: 'AVAILABLE_NOW' },
    { description: 'Verfügbar', name: 'AVAILABLE_DATE', prefix: 'ab' },
    { description: 'Verfügbar', name: 'AVAILABLE_DATE_FREETEXT' },
    { description: 'Befristung', name: 'DURATION/HASTERMLIMIT' },
    { description: 'Befristung', name: 'DURATION/TERMLIMITTEXT' },
    { description: 'Bautyp', name: 'BUILDING_TYPE' },
    { description: 'Heizung', name: 'HEATING' },
    { description: 'Topnummer', name: 'UNIT_NUMBER' },
    { description: 'Zustand', name: 'BUILDING_CONDITION' },
    { description: 'Böden', name: 'FLOOR_SURFACE' },
]

const realEstateAttributeDefinitions_factsAboutProject: AttributeDefinition[] = [
    { description: 'Wohneinheiten', name: 'PROJECT/UNIT_COUNT_BUY' },
    { description: 'Wohneinheiten', name: 'PROJECT/UNIT_COUNT_RENT' },
    { description: 'Wohnfläche', name: 'PROJECT/UNIT_SIZE_FROM' },
    { description: 'Wohnfläche', name: 'PROJECT/UNIT_SIZE_TO' },
    { description: 'Ausbaustufe', name: 'PROJECT/UNIT_CONFIGURATION_LEVEL' },
    { description: 'Status', name: 'PROJECT/STATUS' },
    { description: 'Fertigstellung', name: 'PROJECT/FINISHED_BY' },
    { description: 'Fertigstellung', name: 'PROJECT/FINISHED_BY_FREETEXT' },
]

const realEstateAttributeDefinitions_energyInformation: AttributeDefinition[] = [
    { description: 'HWB (kWh/m²/Jahr)', name: 'ENERGY_HWB' },
    { description: 'HWB Energieklasse', name: 'ENERGY_HWB_CLASS' },
    { description: 'f<sub>GEE</sub>', name: 'ENERGY_FGEE' },
    { description: 'f<sub>GEE</sub> Energieklasse', name: 'ENERGY_FGEE_CLASS' },
]

const realEstateAttributeDefinitions_priceInformation_sale: AttributeDefinition[] = [
    { description: 'Kaufpreis', name: 'ESTATE_PRICE/PRICE_SUGGESTION', formatter: PriceFormatter },
    { description: 'Kaufpreis', name: 'PROJECT/UNIT_PRICE_FROM', formatter: PriceFormatter },
    { description: 'Kaufpreis provisionsfrei', name: 'PROJECT/UNIT_PRICE_TO', formatter: PriceFormatter },
    { description: 'Miete', name: 'PROJECT/UNIT_RENT_FROM', formatter: PriceFormatter },
    { description: 'Gesamtmiete', name: 'PROJECT/UNIT_RENT_TO', formatter: PriceFormatter },
    { description: 'Betriebskosten (inkl. MWSt)', name: 'ADDITIONAL_COST/AMOUNT', formatter: PriceFormatter },
    { description: 'Betriebskosten (exkl. MWSt)', name: 'ADDITIONAL_COST/AMOUNT_NET', formatter: PriceFormatter },
    { description: 'Heizkosten (exkl. MWSt)', name: 'ESTATE_PRICE/HEATINGCOSTSNET', formatter: PriceFormatter },
    { description: 'Sonstige Kosten (exkl. MWSt)', name: 'ESTATE_PRICE/OTHERCOSTS_NET', formatter: PriceFormatter },
    { description: 'Monatliche Kosten (inkl. MWSt)', name: 'ESTATE_PRICE/MONTHCOSTS_GROSS', formatter: PriceFormatter },
    { description: 'Monatliche Kosten (MWSt)', name: 'ESTATE_PRICE/MONTHCOSTS_VAT', formatter: PriceFormatter },
    { description: 'Ablöse', name: 'RENTAL_PRICE/FURNITURE_COST', formatter: PriceFormatter },
    { description: 'Maklerprovision', name: 'ADDITIONAL_COST/FEE', formatter: PriceFormatter },
]

const realEstateAttributeDefinitions_priceInformation_rent: AttributeDefinition[] = [
    { description: 'Gesamtmiete', name: 'RENTAL_PRICE/PER_MONTH', formatter: PriceFormatter },
    { description: 'Gesamtmiete (inkl. MWSt)', name: 'RENTAL_PRICE/PER_MONTH_GROSS', formatter: PriceFormatter },
    { description: 'Gesamtmiete (exkl. MWSt)', name: 'RENTAL_PRICE/PER_MONTH_NET', formatter: PriceFormatter },
    { description: 'Gesamtbelastung', name: 'RENTAL_PRICE/TOTAL_ENCUMBRANCE', formatter: PriceFormatter },
    { description: 'Miete pro Woche', name: 'RENTAL_PRICE/PER_WEEK', formatter: PriceFormatter },
    { description: 'Miete pro Woche', name: 'PRICE_RANGE/PRICE_FROM', formatter: PriceFormatter },
    { description: 'Miete (inkl. MWSt)', name: 'RENTAL_PRICE/GROSS', formatter: PriceFormatter },
    { description: 'Miete (exkl. MWSt)', name: 'RENTAL_PRICE/NET', formatter: PriceFormatter },
    { description: 'Betriebskosten (inkl. MWSt)', name: 'RENTAL_PRICE/ADDITIONAL_COST_GROSS', formatter: PriceFormatter },
    { description: 'Betriebskosten (exkl. MWSt)', name: 'RENTAL_PRICE/ADDITIONAL_COST_NET', formatter: PriceFormatter },
    { description: 'Betriebskosten (inkl. MWSt)', name: 'ADDITIONAL_COST/AMOUNT', formatter: PriceFormatter },
    { description: 'Betriebskosten (exkl. MWSt)', name: 'ADDITIONAL_COST/AMOUNT_NET', formatter: PriceFormatter },
    { description: 'Heizkosten (inkl. MWSt)', name: 'RENTAL_PRICE/HEATINGCOSTSGROSS', formatter: PriceFormatter },
    { description: 'Heizkosten (exkl. MWSt)', name: 'RENTAL_PRICE/HEATINGCOSTSNET', formatter: PriceFormatter },
    { description: 'Sonstige Kosten (inkl. MWSt)', name: 'RENTAL_PRICE/ADDITIONAL_COSTS_GROSS', formatter: PriceFormatter },
    { description: 'Sonstige Kosten (exkl. MWSt)', name: 'RENTAL_PRICE/ADDITIONAL_COSTS_NET', formatter: PriceFormatter },
    { description: 'Mehrwertsteuer', name: 'RENTAL_PRICE/VAT', formatter: PriceFormatter },
    { description: 'Kaution', name: 'ADDITIONAL_COST/DEPOSIT', formatter: PriceFormatter },
    { description: 'Ablöse', name: 'RENTAL_PRICE/FURNITURE_COST', formatter: PriceFormatter },
    { description: 'Maklerprovision', name: 'ADDITIONAL_COST/FEE', formatter: PriceFormatter },
]

const realEstateAttributeDefinitions_priceInformation_extra: AttributeDefinition[] = [
    { description: 'Wohnbauförderung', name: 'PRICE/EXTRAINFO' },
    { description: 'Zusatzinformation', name: 'RENTAL_PRICE/PRICE_DESCRIPTION' },
    { description: 'Zusatzinformation', name: 'ESTATE_PRICE/PRICE_DESCRIPTION' },
]

const autoMotorAttributeDefinitions: AttributeDefinition[] = [
    { description: 'Verkaufspreis', name: 'PRICE_FOR_DISPLAY' },
    { description: 'Verkaufspreis', name: 'PRICE', formatter: PriceFormatter },
    { description: 'Erstzulassung', name: 'YEAR_MODEL', postfix: 'EZ' },
    { description: 'Erstzulassung', name: 'LICENSE' },
    { description: 'Fahrzeugtyp', name: 'CAR_TYPE' },
    { description: 'Kategorie', name: 'CARAVAN_SEGMENT' },
    { description: 'Marke', name: 'CARAVAN_MODEL/MAKE' },
    { description: 'Modell', name: 'CARAVAN_MODEL/MODEL' },
    { description: 'Kategorie', name: 'VAN_SEGMENT' },
    { description: 'Marke', name: 'VAN_MODEL/MAKE' },
    { description: 'Typ', name: 'VAN_MODEL/MODEL' },
    { description: 'Typ', name: 'MC_CATEGORY' },
    { description: 'Nettopreis', name: 'MOTOR_PRICE/NET', formatter: PriceFormatter },
    { description: 'Bonuspreis', name: 'MOTOR_PRICE/BONUS', formatter: PriceFormatter },
    { description: 'monatliche Rate', name: 'LEASING/MONTHLY_RATE', formatter: PriceFormatter },
    { description: 'Anzahlung', name: 'LEASING/ADVANCE_PAYMENT', formatter: PriceFormatter },
    { description: 'Laufzeit in Monaten', name: 'LEASING/CONTRACT_DURATION_MONTHS' },
    { description: 'Laufleistung (p. A.)', name: 'LEASING/MILEAGE_PER_YEAR', postfix: 'km', formatter: NumberFormatter },
    { description: 'Restwert', name: 'LEASING/RESIDUAL_VALUE', formatter: PriceFormatter },
    { description: 'Antrieb', name: 'DRIVE_SYSTEM' },
    { description: 'Kilometerstand', name: 'MILEAGE', postfix: 'km', formatter: NumberFormatter },
    { description: 'Leistung', name: 'ENGINE/EFFECT', formatter: calculatePsValue },
    { description: 'Zustand', name: 'MOTOR_CONDITION' },
    { description: 'Treibstoff', name: 'ENGINE/FUEL' },
    { description: 'Getriebeart', name: 'TRANSMISSION' },
    { description: 'Antrieb', name: 'WHEEL_DRIVE' },
    { description: 'Farbe', name: 'EXTERIOR_COLOUR' },
    { description: 'Außenfarbe', name: 'EXTERIOR_COLOUR_MAIN' },
    { description: 'Anzahl Türen', name: 'NO_OF_DOORS' },
    { description: 'Anzahl Sitze', name: 'NO_OF_SEATS' },
    { description: 'Vorbesitzer', name: 'NO_OF_OWNERS' },
    { description: 'Garantie', name: 'WARRANTY' },
    { description: 'Garantiename', name: 'WARRANTY_TYPE' },
    { description: 'Garantie bis', name: 'WARRANTY_DURATION' },
    { description: 'Gewährleistung', name: 'DEFECTS_LIABILITY' },
    { description: '§57a "Pickerl"', name: 'CONDITION_REPORT' },
    { description: 'Letzte 57a Prüfung', name: 'CONDITION_REPORT_DATE' },
    { description: 'Pickerl gültig bis', name: 'CONDITION_REPORT_VALID_UNTIL' },
    { description: 'Hubraum', name: 'ENGINE/VOLUME', postfix: 'ccm', formatter: NumberFormatter },
    { description: 'Hubraum', name: 'ENGINEVOLUME', postfix: 'ccm', formatter: NumberFormatter },
    { description: 'Zylinder', name: 'ENGINE/CYLINDER' },
    { description: 'zul. Gesamtgewicht', name: 'MAX_TOTAL_WEIGHT', postfix: 'kg', formatter: NumberFormatter },
    { description: 'zul. Gesamtgewicht', name: 'CARAVAN_WEIGHT/WEIGHT_TOT', postfix: 'kg', formatter: NumberFormatter },
    { description: 'Leergewicht', name: 'CARAVAN_WEIGHT/WEIGHT_NET', postfix: 'kg', formatter: NumberFormatter },
    { description: 'max. Anhängelast', name: 'TRAILER_LOAD', postfix: 'kg', formatter: NumberFormatter },
    { description: 'CO₂-Ausstoß', name: 'CO2_FOOTPRINT', postfix: 'g/km', formatter: NumberFormatter },
    { description: 'Abgasnorm', name: 'EMISSION_STANDARD' },
    { description: 'Verbrauch', name: 'CONSUMPTION', postfix: 'l/100km', formatter: NumberFormatter },
    { description: 'Länge', name: 'LENGTH_CM', postfix: 'cm', formatter: NumberFormatter },
    { description: 'Breite', name: 'WIDTH_CM', postfix: 'cm', formatter: NumberFormatter },
    { description: 'Anzahl Betten', name: 'NO_OF_BERTHS' },
    { description: 'Anhängelast gebremst', name: 'TRAILER_LOAD' },
    { description: 'MwSt. ausweisbar', name: 'EQUIPMENT_VAT_REPORTABLE' },
    { description: 'Behinderten\u00ADgerecht', name: 'EQUIPMENT_WHEELCHAIR_ACCESSIBLE' },
    { description: 'Metallic\u00ADlackierung', name: 'EQUIPMENT_METALLIC_PAINT' },
    { description: 'Leasingfähig', name: 'EQUIPMENT_LEASABLE' },
    { description: 'Servicegepflegt', name: 'EQUIPMENT_COMPLETE_SERVICE_BOOKLET' },
    { description: 'Reichweite (WLTP)', name: 'ELECTRIC_VEHICLE/WLTP_RANGE', postfix: 'km', formatter: NumberFormatter },
    { description: 'Akkukapazität', name: 'ELECTRIC_VEHICLE/BATTERY_CAPACITY', postfix: 'kWh', formatter: NumberFormatter },
]

export const attributeDefinitions: AttributeDefinition[] = [
    ...autoMotorAttributeDefinitions,
    ...realEstateAttributeDefinitions_objectInformation,
    ...realEstateAttributeDefinitions_factsAboutProject,
    ...realEstateAttributeDefinitions_energyInformation,
    ...realEstateAttributeDefinitions_priceInformation_sale,
    ...realEstateAttributeDefinitions_priceInformation_rent,
    ...realEstateAttributeDefinitions_priceInformation_extra,
]
