import { formatNumber } from '@wh/common/chapter/lib/formatter'

export const calculatePsValue = (value: string | undefined) => {
    return calculateValueAndFormat(value, (a, b) => a * b)
}

export const calculatePsValueRaw = (value: string | undefined) => {
    return calculateValue(value, (a, b) => a * b)
}

export const calculateKwValueRaw = (value: string | undefined) => {
    return calculateValue(value, (a, b) => a / b)
}

const calculateValueAndFormat = (value: string | undefined, multiplyOrDivide: (a: number, b: number) => number) => {
    return formatNumber(calculateValue(value, multiplyOrDivide))
}

const calculateValue = (value: string | undefined, multiplyOrDivide: (a: number, b: number) => number) => {
    const parsedValue = value
        ?.replace(/\s/g, '')
        .replace(/[,]/g, '.')
        .replace(/[.](?![0-9]{0,2}$)/g, '')
    const engineEffectKw = parsedValue && !isNaN(+parsedValue) ? parsedValue : '0'
    return Math.round(multiplyOrDivide(Number(engineEffectKw), 1.35962)).toString()
}
