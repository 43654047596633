import {
    AttributesItem,
    AttributesList,
    FormattedAttributesItem,
    FormattedAttributesList,
    getAttributeValue,
} from '@wh/common/chapter/types/Attributes'
import { EquipmentList } from '@bbx/common/types/ad-detail/Equipments'
import { convertSpecialEquipmentsIntoAttributes } from '@bbx/common/lib/equipmentAttributes'
import { getLicenseAttribute } from '@bbx/common/lib/licenseAttribute'
import { attributeDefinitions } from '@bbx/common/lib/attribute-definitions'

export function formatAttributes(attributes: AttributesList, equipmentList?: EquipmentList): FormattedAttributesList {
    const attributesWithLicense = hackyAddLicenseAttribute(attributes)

    let formattedAttributes = attributesWithLicense.attribute.map((a) => formatAttribute(a))

    if (equipmentList) {
        formattedAttributes = [
            ...formattedAttributes,
            ...convertSpecialEquipmentsIntoAttributes(equipmentList).map((a) => formatAttribute(a)),
        ]
    }

    formattedAttributes = hackyAddPricePerSquareMeterPostfix(formattedAttributes)

    return { attribute: formattedAttributes }
}

const hackyAddLicenseAttribute = (attributes: AttributesList): AttributesList => {
    const licenseAttribute = getLicenseAttribute(attributes)
    return licenseAttribute ? { attribute: [licenseAttribute, ...attributes.attribute] } : attributes
}

const hackyAddPricePerSquareMeterPostfix = (attributes: FormattedAttributesItem[]) => {
    const priceUnitAttributeValue = getAttributeValue(attributes, 'PRICE/UNIT') || getAttributeValue(attributes, 'RENTAL_PRICE/UNIT')

    if (priceUnitAttributeValue) {
        return attributes.map((attribute) =>
            attribute.name === 'ESTATE_PRICE/PRICE_SUGGESTION' || attribute.name === 'RENTAL_PRICE/PER_MONTH_GROSS'
                ? { ...attribute, postfix: priceUnitAttributeValue }
                : attribute,
        )
    }

    return attributes
}

const formatAttribute = (attribute: AttributesItem): FormattedAttributesItem => {
    const attributeDefinition = attributeDefinitions.find((definition) => definition.name === attribute.name)

    return {
        name: attribute.name,
        values: attribute.values,
        description: attributeDefinition?.description,
        prefix: attributeDefinition?.prefix,
        postfix: attributeDefinition?.postfix,
        formattedValues: attribute.values.map((value) => attributeDefinition?.formatter?.(value) ?? value),
    }
}
