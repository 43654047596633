import { AttributeValueFormatter } from '@bbx/common/lib/AttributeValueFormatter'

export const RealEstatePropertyTypeFormatter: AttributeValueFormatter = (value: string) => {
    switch (value) {
        case 'Genossenschaftswohnung':
            return 'Genossenschafts\u00ADwohnung'
        case 'Ferienwohnung/Ferienhaus':
            return 'Ferienwohnung/\u200BFerienhaus'
    }

    return value
}
