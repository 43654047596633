import { FormattedAttributesItem, getAttribute } from '@wh/common/chapter/types/Attributes'
import { AttributeName } from '@wh/common/chapter/types/AttributeName'
import { AdvertStatus } from '@bbx/common/types/ad-detail/AdvertDetails'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { isFormattedPrice } from '@wh/common/chapter/lib/formatter'
import { Price } from '@bbx/common/types/Price'

export const getPriceAttribute = (attributes: FormattedAttributesItem[], key: AttributeName): Price => {
    const attr = getAttribute(attributes, key)
    return {
        key,
        label: attr?.description,
        value: attr?.formattedValues?.[0],
    }
}

export const getPriceByStatus = (price: Price, adStatus: AdvertStatus | null): Price => {
    if (adStatus && adStatus.id !== adStatusFlag.ACTIVE) {
        const label = price.value && isFormattedPrice(price.value) ? `${price.value} ${price.label}` : undefined
        const value = adStatus.description.charAt(0).toUpperCase() + adStatus.description.slice(1)
        return { key: price.key, label, value }
    }
    return price
}
