import { AttributesItem, AttributesList, getAttributeValue } from '@wh/common/chapter/types/Attributes'

export const getLicenseAttribute = (advertAttributes: AttributesList): AttributesItem | undefined => {
    const yearModelMonth = getAttributeValue(advertAttributes.attribute, 'YEAR_MODEL_MONTH')
    const yearModel = getAttributeValue(advertAttributes.attribute, 'YEAR_MODEL')

    if (yearModelMonth && yearModel) {
        return buildAttribute(`${yearModelMonth}/${yearModel}`)
    }
    if (yearModel && !yearModelMonth) {
        return buildAttribute(yearModel)
    } else {
        return undefined
    }
}

const buildAttribute = (value: string): AttributesItem => {
    return { name: 'LICENSE', values: [value] }
}
